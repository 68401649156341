@import "../../utils/styles/variables.scss";
@import "../../utils/styles/mixin.scss";

footer {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $dark;
    gap: 20px;

    .footer__logo {
        width: 5%;
        object-fit: contain;

        @media screen and (max-width: 765px) {
            width: 10%;

        }

    }

    .nav__footer {
        list-style: none;
        display: flex;
        padding: 0;
        gap: 30px;
        font-size: 12px;

        @media screen and (max-width: 765px) {
            gap: 14px;
        }

        * {
            text-decoration: none;
            color: $Gris400;
        }


    }

    .footer_reseau {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: 765px) {
            flex-wrap: wrap;
            justify-content: flex-start;
        }

        * {
            color: $Gris400;
        }

        .footer__link {
            display: flex;
            gap: 20px;

            @media screen and (max-width: 765px) {
                order: -1;
                width: 100%;
                justify-content: center;
            }
        }

        span {
            width: 20%;
            font-size: 12px;

            @media screen and (max-width: 765px) {
                width: 60%;

            }

            &:nth-last-of-type(2) {
                @media screen and (max-width: 765px) {
                    width: 32%;

                }

            }

            .dark__mode__btn {
                border-radius: 20px;
                width: 30px;
                height: 30px;
                background: none;
                border: 1px solid $Gris400;

            }
        }



    }

}