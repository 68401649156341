@import "../../utils/styles/variables.scss";
@import "../../utils/styles/mixin.scss";

.contact {
    * {
        padding: 0;
        margin: 0;
    }


    .contact__body {
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 765px) {
            flex-direction: column;
            gap: 30px;

        }


        .coordonnees {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 13px;
            width: 30%;
@media screen and (max-width: 765px) {
           width: 100%;
            
        }

        }
    }
}