@import "../../utils/styles/mixin.scss";

.welcome {
  position: relative;

  .welcome__img {
    height: 100vh;
    width: 100%;
    object-fit: cover;
  }

  &__bloc__title {
    position: absolute;
    left: 10%;
    top: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;

    @media screen and (max-width: 765px) {
      top: 20%;
    }


    .letter-by-letter {
      color: white;
      width: 100%;
      align-content: center;
      text-align: left;
      line-height: 48px;



      .hello {
        margin: 0;
        font-size: 20px;
        font-weight: 200;

        @media screen and (max-width: 765px) {
          font-size: 30px;
        }
      }

      .tap-bar {
        animation: typing 1s ease-in-out infinite;
        animation-delay: 0.5s;
      }
    }
  }

  .welcome__cover {
    @include absolute-full;
    background-color: black;
    opacity: 0.5;
  }

  .welcome__down__page {
    position: absolute;
    bottom: 5%;
    z-index: 10;
    color: white;
    right: 49%;
    width: 3%;
    padding: 4px;
    text-align: center;
    border: 2px solid transparent;

    border-radius: 13px;
    height: 50px;


    i {
      font-size: 20px;
      animation: move 1s ease-in-out 0s infinite alternate;
    }
  }

  .welcome__link {
    display: flex;
    gap: 20px;
    align-self: baseline;
    transform: translateY(-20px);
    opacity: 0;
    transition: all 0.7s ease-in-out;

    @media screen and (max-width: 765px) {
      gap: 50px;
      margin-top: 40px;
    }

    a {
      text-decoration: none;
      color: white;
      font-size: 22px;

      @media screen and (max-width: 765px) {
        font-size: 30px;
      }
    }
  }
}


@keyframes move {
  0% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(10px);
  }
}


@keyframes typing {
  0% {
    display: none;
    /* Start with text hidden */
  }

  50% {
    display: inline-block;
    /* Text appears */
  }

  100% {
    opacity: 0;
    /* Text disappears */
  }
}