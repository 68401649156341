@import "../../utils/styles/variables.scss";
@import "../../utils/styles/mixin.scss";


.details {
    display: flex;
    height: 100%;
    overflow: hidden;
    gap: 4%;

    @media screen and (max-width: 766px) {
        flex-direction: column;
    }

    .details__cover {
        order: 1;
        width: 56%;
        height: 100%;
        overflow: hidden;

        @media screen and (max-width: 766px) {
            order: -1;
            width: 100%;
            height: 30%;
        }

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .details__body {
        padding: 2%;
        padding-top: 8%;
        width: 60%;

        @media screen and (max-width: 766px) {
            padding-top: unset;
        padding: 4%;
            width: 100%;

        }



        .details__body__title {
            font-size: 28px;
            margin: 0;
            margin-bottom: 4%;
        }

        .details__body__subtitle {
            margin: 0;
            font-size: 15px;

            &:nth-of-type(1) {
                margin-bottom: 4%;
            }

            span {
                font-weight: 400;
                font-size: 14px;

            }
        }

        p {
            font-size: 14px;
            text-align: justify;
        }

        .details__body__block {
            display: flex;
            gap: 14px;
            padding-bottom: 16px;
            @media screen and (max-width: 766px) {
                gap: 8px;
            }
            .details__body__block__tags {
                background: $Main800;
                border: none;
                border-radius: 4px;
                color: white;
                padding: 4px 6px;
                @media screen and (max-width: 766px) {
                    font-size: 10px;
        
                }

            }
        }

    }
}