@import "../../utils/styles/variables.scss";
@import "../../utils/styles/mixin.scss";


.coordonnee {
    border: 1px solid $Gris300;
    border-radius: 5px;
    padding: 22px 15px !important;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    background: $dark;
    color: white;

    div {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    p {
        font-size: 14px;
        color: $Gris400;
    }

    i {
        font-size: 24px;
        margin-left: 5%;
        color: $Gris400;
    }
}