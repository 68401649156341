header {
    //display: block !important;
}

.error {
    height: 70vh;
    display: flex;
    justify-content: center;
    position: relative;

    @media screen and (max-width: 765px) {
        height: 78vh;
    }
    img {
        width: 50%;
        object-fit: contain;
    }

    .back__home{
        position: absolute;
        bottom: 10%;
        color: black;
        font-weight: bolder;
        padding: 10px !important;
    }
}