@import "../../utils/styles/variables.scss";
@import "../../utils/styles/mixin.scss";


.form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 68%;
    border: 1px solid $Gris300;
    padding: 3%;
    background: $dark;
    position: relative;

    @media screen and (max-width: 765px) {
        width: 100%;

    }

    .loader {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba($Main800, 0.8) !important;
        top: 0;
        left: 0;
        z-index: 10;
        opacity: 0.5;
        display: none;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .floating__label {
        position: relative;

        input,
        textarea {
            width: 100%;
            height: 50px;
            padding-left: 2%;
            padding-top: 3%;
            border: 1px solid $Gris300;
            border-radius: 4px;

            &:not(:placeholder-shown)+label,
            &:focus+label {
                transform: translateY(-10px);
                font-size: 12px;
                font-weight: 400;
                color: $Gris600;
            }
        }

        label {
            position: absolute;
            top: 35%;
            left: 2%;
            font-size: 14px;
            font-weight: 600;
            transition: all 0.3s ease-in-out;
        }

        textarea {
            padding-top: 8%;
            height: 90px;

            @media screen and (max-width: 765px) {
                padding-top: 12%;

            }
        }



    }


    .form__row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .form__row__col {
            width: 49%;
        }

        .form__submit {
            background-color: $Main800;
            color: white;
            padding: 10px 30px;
            border: none;
            border-radius: 2px;

            &:disabled {
                cursor: not-allowed;
                background-color: $Gris400;

            }
        }

        .mail__success {
            color: white;
            display: none;
            font-size: 14px;
            margin: 0;

        }

        .mail__error {
            color: #ffaaaa;
            margin-left: 14px;
            display: none;
            font-size: 14px;
            margin: 0;

        }
    }

}