@import "../../utils/styles/variables.scss";
@import "../../utils/styles/mixin.scss";

.competences {

  .competences__logiciels {
    display: flex;
    gap: 20px;
    justify-content: center;

    @media screen and (max-width: 765px) {
      flex-wrap: wrap;
    }
    
  }
}
