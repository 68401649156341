@import "../../utils/styles/variables.scss";
@import "../../utils/styles/mixin.scss";


.ReactModal__Overlay,
.ReactModal__Overlay--after-open {
    z-index: 30;
    background-color: rgba($Main800, 0.6) !important;
}

.ReactModal__Content,
.ReactModal__Content--after-open {
    top: 20% !important;
    left: 10% !important;
    height: 80%;
    width: 80%;
    padding: 0 !important;

    @media screen and (max-width: 766px) {
        width: 100%;
        left: 0 !important;
        border-radius: 20px 20px 0 0 !important;

    }

}

.modal__header {
    position: absolute;
    top: 4%;
    left: 2%;

    @media screen and (max-width: 766px) {
        top: 2%;

    }

    .btn__close {
        background: white;
        border: none;
        font-size: 22px;
        border-radius: 20px;
        width: 40px;
        height: 40px;
        text-align: center;
        align-content: center;
        padding: 0 !important;
    }
}