$Main800:#094975;
$Main700:#166397;
$Main400:#3F9CDB;
$Main400:#3F9CDB;
$Main200:#9fd2f7;
$Main50:#EEF8FF;

$dark:#001624;

$Gris800:#1e293b;
$Gris700:#334155;
$Gris600:#475569;
$Gris500:#64748b;
$Gris400:#94a3b8;
$Gris300:#cbd5e1;
$Gris200:#e2e8f0;
$Gris100:#f1f5f9;
$Gris50:#f8fafc;

