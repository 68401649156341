@import "../../utils/styles/variables.scss";
@import "../../utils/styles/mixin.scss";

.logiciel {
  width: 10%;
  height: 100px;
  position: relative;
  overflow: hidden;
  border-radius: 12px;

  @media screen and (max-width: 765px) {
    width: 30%;
    height: 100px;
  }

  &>* {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease-in-out;
  }

  &__bg {
    background: $Gris100;
    opacity: 1;
    transform: scale(1);

    img {
      width: 30%;
      object-fit: contain;
    }
  }

  &__cover {
    position: absolute;
    top: 0;
    left: 0;
    background-color: $Main800;
    color: white;
    font-weight: 500;
    transform: scale(1.2);
    opacity: 0;
  }

  &:hover .logiciel__bg {
    transform: scale(1.2);
    opacity: 0;
  }

  &:hover .logiciel__cover {
    transform: scale(1);
    opacity: 1;
  }
}