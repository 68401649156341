@import "../../utils/styles/variables.scss";
@import "../../utils/styles/mixin.scss";

.projet {
    height: 300px;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    cursor: pointer;




    .projet__cover {
        width: 100%;
        height: 70%;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all 0.3s ease-in-out;

            @media screen and (max-width: 766px) {
                transform: scale(1.1);
            }
        }
    }

    .projet__footer {
        background-color: $dark;
        color: white;
        height: 30%;
        padding: 5%;

        * {
            padding: 0;
            margin: 0;
        }

        .projet__footer__title {
            margin-bottom: 10px;
        }

        .projet__footer__link {
            opacity: 0;
            font-size: 12px;
            font-weight: 400;
            display: flex;
            gap: 18px;
            padding: 1% 0;
            transition: all 0.3s ease-in-out;

            @media screen and (max-width: 766px) {
                opacity: 1;
            }

            a,
            button {
                text-decoration: none;
                color: white;

                i {
                    font-size: 16px;
                }
            }

            button {
                background: none;
                border: none;
            }


        }

        .projet__footer__tag {
            font-size: 12px;
            font-weight: 400;
            transition: all 0.3s ease-in-out;

            @media screen and (max-width: 766px) {
                display: none;
            }
        }
    }


    // Animation
    &:hover img {
        transform: scale(1.1);
    }

    &:hover .projet__footer__tag {
        transform: translateY(-20px);
        opacity: 0;
    }

    &:hover .projet__footer__link {
        transform: translateY(-20px);
        opacity: 1;
    }


    &:nth-of-type(4) {

        @media screen and (min-width: 766px) {
            grid-column: span 2;
            grid-row: span 2;
            height: 630px;

            .projet__cover {
                height: 80%;
            }

            .projet__footer {
                height: 20%;

                &__title {
                    margin-bottom: 10px;

                }

            }

        }


    }



}