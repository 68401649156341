@import "./utils/styles/variables.scss";
@import "./utils/styles/mixin.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  box-sizing: border-box;
}

header,
section,
footer {
  padding: 2% 8%;
}


.section__title {
  position: relative;
  margin: 70px auto;
  text-align: center;
  font-size: 32px;
  font-weight: 500;

  span {
    font-size: 80px;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    top: -34px;
    z-index: 0;
    opacity: 0.03;
    font-weight: 700;

    @media screen and (max-width: 765px) {
      font-size: 52px;
      top: -15px;


    }

  }
}



.dark {

  &>*,
  .nav__header {
    background: $dark !important;
    color: white !important;
  }

  .nav__menu_bg {
    background: white;
  }

  header a {
    color: white !important;
  }

  form label {
    color: $dark;
  }

  .logo--blanc {
    display: block !important;
  }

  .logo--noir {
    display: none !important;
  }


}