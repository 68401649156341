@import "../../utils/styles/variables.scss";
@import "../../utils/styles/mixin.scss";

.a-propos {

  .me__contacter{
    display: flex;
    justify-content: center;
    margin-top: 40px;

 

    .btn{
      background-image: linear-gradient(120deg, $Main700 0%, $Main400 100%);
      padding: 10px 20px;
      border: none;
      color: white;
      border-radius: 5px;
      font-weight: 400;
      text-decoration: none;
      text-align: center;
      vertical-align: middle;
      align-content: center;
      @media screen and (max-width: 765px) {       
        height: 50px;
        width: 60%;
      }
    }
  }
  

  .placeholder {
    display: flex;
    gap: 30px;

    @media screen and (max-width: 765px) {
      flex-direction: column;
      align-items: center;
    }

    img {
      width: 45%;
      object-fit: contain;

      @media screen and (max-width: 765px) {
        order: 1;
      width: 100%;
        
      }
    }

    p {
      text-align: justify;
      line-height: 30px;
    }
  }
}


